import axios from 'axios';
import React, { useEffect, useState } from "react";
import InnerPageLayout from "../components/Layout/InnerPageLayout";
import BreadCrumb from "../components/Breadcrumb/BreadCrumb";
import ProjectOverview from "../components/Projects/ProjectOverview";
import ProjectRequirement from "../components/Projects/ProjectRequirement";
import ProjectResult from "../components/Projects/ProjectResult";
import RelatedProject from "../components/Projects/RelatedProject";
import { useParams } from "react-router-dom";
import { generateSlug } from "../utils/custom-filter";


export default function ProjectDetails() {
    const { slug } = useParams();
    console.log(generateSlug(slug));

    const [project, set_project] = useState([]);

    useEffect(() => {
        const getCategory = async () => {
            try {
                const requestBody = {
                    slug: slug,
                    // slug: generateSlug(slug),
                };
                const response = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/projects-view`,
                    requestBody
                );
                set_project(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getCategory();
    }, [slug]);

    return (
        <InnerPageLayout>
            <BreadCrumb
                pageTitle="Project Details"
                currentPage="Project Details"
                href="/project-details"
            />
            <ProjectOverview />
            <ProjectRequirement />
            <ProjectResult />
            <RelatedProject />
        </InnerPageLayout>
    );
}
