import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ServiceMain() {
    const [service_list, set_service_list] = useState([]);
    useEffect(() => {
        const getServices = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/services-view`
                );
                set_service_list(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getServices();
    }, []);
    return (
        <section id="service" className="w-full">
            <div className="mx-auto max-w-[1600px] w-full xl:py-[130px] py-[60px] xl:px-[80px] md:px-10 px-0 bg-main-gray rounded-[10px] border border-[#e7e3fa]">
                <div className="relative w-full service-section-wrapper">
                    <div className="relative z-10 mx-auto theme-container">
                        <div className="flex flex-col items-center">
                            <span className="text-purple font-medium px-5 py-3 border border-[#e7e3fa] leading-none rounded-full inline-block mb-5 bg-white">
                                Explore Services
                            </span>
                            <h2 className="sm:text-48 text-24 font-semibold text-main-black mb-[50px] text-center lg:w-[685px] w-full">
                                High Impact Marketing Services to grow your business
                            </h2>
                        </div>
                        <div className="w-full grid xl:grid-cols-12 md:grid-cols-6 grid-cols-3 xl:gap-[30px] gap-5">
                            {service_list.map((item, index) => (
                                <div key={index}
                                    data-aos="zoom-out"
                                    className="col-span-3 relative group border border-transparent common-transition before:inline-block before:w-full before:h-full before:border before:rounded-[10px] before:border-purple before:absolute before:bottom-0 before:right-0 before:shadow-common before:origin-bottom-right before:scale-0 hover:before:scale-100 before:transition-all before:duration-300 before:z-0 after:inline-block after:w-full after:h-full after:border after:rounded-[10px] after:border-purple after:absolute after:top-0 after:left-0 after:shadow-common after:origin-top-left after:scale-0 hover:after:scale-100 after:transition-all after:duration-300 after:z-0 before:ease-linear after:ease-linear"
                                >
                                    <div className="flex flex-col relative px-4 md:px-8 py-5 md:py-10 justify-between items-center rounded-[10px] bg-white m-[1px] z-10">
                                        <div className="w-[60px] h-[60px] flex justify-center items-center rounded-full common-transition text-purple">
                                            <span>
                                                <i className={item.icon + " h-8"} ></i>
                                            </span>
                                        </div>
                                        <div className="mt-5 mb-4">
                                            <p className="mb-4 font-semibold text-center text-20 text-main-black">
                                                {item.title}
                                            </p>
                                            <p className="text-center text-paragraph">
                                                {item.description}
                                            </p>
                                        </div>
                                        {item.slug &&
                                            <Link to={`/service-details/${item.slug}`}>
                                                <div className="flex items-center gap-2 group text-paragraph hover:text-purple">
                                                    <span className="relative font-medium leading-5 border-b border-transparent font-inter before:inline-block before:border-purple before:border-b before:absolute before:bottom-0 before:transition-all before:duration-300 before:w-0 hover:before:w-full before:overflow-hidden before:h-5">
                                                        Read More
                                                    </span>
                                                    <svg
                                                        width="7"
                                                        height="11"
                                                        viewBox="0 0 7 11"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M1.5 10L5.29289 6.20711C5.62623 5.87377 5.79289 5.70711 5.79289 5.5C5.79289 5.29289 5.62623 5.12623 5.29289 4.79289L1.5 1"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
