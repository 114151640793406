import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ProjectList() {
    const [project_list, set_project_list] = useState([]);

    useEffect(() => {
        const getCategory = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/projects-view`
                );
                set_project_list(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getCategory();
    }, []);
    return (
        <section className="w-full py-16 md:py-[130px]">
            <div className="w-full mx-auto theme-container">
                <div className="flex flex-col items-center">
                    <div className="py-0.5 px-5 bg-purple/5 border-purple/10 border rounded-[30px] font-medium text-purple mb-5">
                        <span>Our Cases Story</span>
                    </div>
                    <div className="mb-[60px]">
                        <h2 className="text-main-black font-semibold text-24 sm:text-48 text-center max-w-[819px]">
                            Our Journey to Success Navigating Challenges, Achieving
                            Milestones, and Building a Legacy
                        </h2>
                    </div>
                </div>
                <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-[30px]">
                    {project_list.map((item, index) => (
                        <div key={index} data-aos="fade-left" className="col-span-4">
                            <div className="pb-[92px] pr-[30px] w-full bg-white relative">
                                <img
                                    src={item.image}
                                    alt=""
                                    className="w-full max-w-[380px] min-h-[350px] max-h-[350px]"
                                />
                                <div className="group shadow-card pl-5 pr-5 md:pl-10 md:pr-16 lg:pl-5 lg:pr-5 xl:pl-10 xl:pr-16 py-5 rounded-2xl absolute top-[50%] md:top-[63%] z-10 bg-white -right-0 h-[146px] hover:h-[185px] transition-all duration-300 hover:bg-it-blue hover:shadow-blue-card hover:-translate-y-[22px] overflow-hidden h5-story_slider_active_card">
                                    <img
                                        src="/assets/images/home-five/hero/about-card-shape.webp"
                                        alt=""
                                        className="absolute right-2 top-bottom-moving"
                                    />
                                    <h1 className="transition-all duration-300 text-paragraph group-hover:text-white">
                                        {item.created_at}
                                    </h1>
                                    <h2 className="text-18 sm:text-20 font-semibold mt-3 max-w-[255px] group-hover:text-white">
                                        {item.name}
                                    </h2>
                                    <Link to={`/project-details/${item.slug}`}>
                                        <div className="flex items-center gap-2 mt-4 group">
                                            <span className="relative font-medium leading-5 text-white border-b border-transparent font-inter before:inline-block before:border-white before:border-b before:absolute before:bottom-0 before:transition-all before:duration-300 before:w-0 hover:before:w-full before:overflow-hidden before:h-5">
                                                Read More
                                            </span>
                                            <svg
                                                width="7"
                                                height="11"
                                                viewBox="0 0 7 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.5 10L5.29289 6.20711C5.62623 5.87377 5.79289 5.70711 5.79289 5.5C5.79289 5.29289 5.62623 5.12623 5.29289 4.79289L1.5 1"
                                                    stroke="#ffff"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
