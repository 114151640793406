import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InnerPageLayout from "../components/Layout/InnerPageLayout";
import BlogBreadCrumb from "../components/Breadcrumb/BlogBreadCrumb";
import BlogDetailsInfo from "../components/BlogInfo/BlogDetailsInfo";

export default function BlogDetails() {
    const { slug } = useParams();
    const [blog, set_blog] = useState([]);

    useEffect(() => {
        const getCategory = async () => {
            try {
                const requestBody = {
                    slug: slug,
                };
                const response = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/blogs-view`,
                    requestBody
                );
                set_blog(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getCategory();
    }, [slug]);
    return (
        <InnerPageLayout>
            <BlogBreadCrumb blog={blog[0]} />
            <BlogDetailsInfo blog={blog[0]} />
        </InnerPageLayout>
    );
}
