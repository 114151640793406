import React, { useEffect } from "react";

export default function Pagination({ pagination, onPageChange }) {
    const { current_page, last_page } = pagination;

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5; // Adjust the number of visible pages as needed

        if (last_page <= maxVisiblePages) {
            for (let i = 1; i <= last_page; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (current_page <= 3) {
                pageNumbers.push(1, 2, 3, 4, "...", last_page);
            } else if (current_page > last_page - 3) {
                pageNumbers.push(1, "...", last_page - 3, last_page - 2, last_page - 1, last_page);
            } else {
                pageNumbers.push(1, "...", current_page - 1, current_page, current_page + 1, "...", last_page);
            }
        }
        return pageNumbers;
    };

    const pageNumbers = getPageNumbers();
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        scrollToTop();
    }, [current_page]);

    return (
        <div className="flex items-center gap-[15px] mt-[70px]">
            {/* Previous Button */}
            <button
                aria-label="left"
                className={`p-4 group ${current_page === 1 ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                disabled={current_page === 1}
                onClick={() => onPageChange(current_page - 1)}
            >
                <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        className="transition-colors duration-300 group-hover:stroke-main-black"
                        d="M5.80078 1L1.00078 7L5.80078 13"
                        stroke="#6D6D6D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>

            {/* Page Numbers */}
            {pageNumbers.map((page, index) =>
                page === "..." ? (
                    <span key={`ellipsis-${index}`} className="text-gray-500">
                        ...
                    </span>
                ) : (
                    <button
                        key={`page-${page}`}
                        className={`flex items-center justify-center transition-all duration-300 rounded-full w-11 aspect-square ${page === current_page
                            ? "bg-purple text-white"
                            : "bg-purple/10 text-paragraph hover:bg-purple hover:text-white"
                            }`}
                        onClick={() => onPageChange(page)}
                    >
                        <span>{page}</span>
                    </button>
                )
            )}

            {/* Next Button */}
            <button
                aria-label="right"
                className={`p-4 group ${current_page === last_page
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                    }`}
                disabled={current_page === last_page}
                onClick={() => onPageChange(current_page + 1)}
            >
                <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        className="transition-colors duration-300 group-hover:stroke-main-black"
                        d="M1 1L5.8 7L0.999999 13"
                        stroke="#6D6D6D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
    );
}
