import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import InnerPageLayout from "../components/Layout/InnerPageLayout";
import Pagination from "../components/Pagination";
import BreadCrumb from "../components/Breadcrumb/BreadCrumb";
import { Link } from "react-router-dom";

export default function Blogs() {
    const navigate = useNavigate();
    const location = useLocation();

    const [category_list, set_category_list] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [tag_list, set_tag_list] = useState([]);

    useEffect(() => {
        const getCategory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/blog-categories-view`
                );
                set_category_list(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getCategory();

        const getTag = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/blogtags-view`
                );
                set_tag_list(response.data.data);
            } catch (error) {
                console.log(error);
            }
        }
        getTag();
    }, []);

    // Set selected category and tag from query parameters
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const category = searchParams.get("category");
        const tag = searchParams.get("tag");

        if (category) {
            setSelectedCategory(parseInt(category, 10));
        }
        if (tag) {
            setSelectedTag(parseInt(tag, 10));
        }
    }, [location.search]);
    
    const updateQueryParams = (key, value) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        navigate(`?${searchParams.toString()}`);
    };

    const handleCategoryClick = (id) => {
        updateQueryParams('category', id);
        setSelectedCategory(id);
    };

    const handleTagClick = (id) => {
        updateQueryParams('tag', id);
        setSelectedTag(id);
    };

    // const [pagination, setPagination] = useState({
    //     "from": 1,
    //     "to": 1,
    //     "current_page": 5,
    //     "last_page": 10,
    //     "per_page": 5,
    //     "total": 100
    // });

    //** Pagination **/ 
    const [pagination, setPagination] = useState(null);
    const handlePageChange = (page) => {
        console.log(page);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", page);
        navigate(`?${searchParams.toString()}`);
    };
    const [blogs_list, set_blogs_list] = useState([]);
    const fetchBlogs = async (locationSearch, setPagination) => {
        try {
            const searchParams = new URLSearchParams(locationSearch);
            const requestBody = {
                category_id: searchParams.get("category"),
                page: searchParams.get("page") || 1,
                tag_ids: searchParams.get("tag"),
                limit: 5,
            };
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/blogs-view`,
                requestBody
            );
            // console.log(response.data.pagination);
            setPagination(response.data.pagination);
            set_blogs_list(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchBlogs(location.search, setPagination);
    }, [location.search]);

    return (
        <InnerPageLayout>
            <BreadCrumb pageTitle="Blogs" href="/blogs" currentPage="Blogs" />
            <section className="py-16 md:py-[130px]">
                <div className="theme-container w-full mx-auto grid grid-cols-8 lg:grid-cols-12 gap-y-16 lg:gap-y-0 lg:gap-x-[70px]">
                    {/* <!-- Left Side blogs start  --> */}
                    <div className="col-span-8">
                        {/* <!-- single blog start --> */}
                        {blogs_list.map((item, index) => (
                            <div className="w-full pb-6" key={index}>
                                <img
                                    src={item.image}
                                    alt=""
                                    className="object-cover w-full max-w-[850px] max-h-[500px]"
                                />
                                <div className="flex flex-col sm:flex-row mt-5 gap-2 md:gap-[30px]">
                                    {/* <div className="flex items-center gap-2.5">
                                    <img
                                        src="./assets/images/blogs/author-1.webp"
                                        alt=""
                                        className="w-[30px] aspect-square rounded-full object-cover"
                                    />
                                    <p className="text-paragraph">Leroy H. Levin</p>
                                </div> */}
                                    <div className="flex items-center gap-2.5">
                                        <div className="w-[5px] aspect-square rounded-full bg-purple/20"></div>
                                        <p className="text-paragraph">{item.created_at}</p>
                                    </div>
                                </div>
                                <hr className="mt-5 border-purple/10" />
                                <h1 className="text-24 sm:text-[30px] leading-[40px] tracking-tight text-main-black font-semibold mt-[30px]">
                                    {item.name}
                                </h1>
                                <p className="text-paragraph mt-[22px] mb-[25px]">
                                    {item.description}
                                </p>
                                <Link to={`/blog-details/${item.slug}`}>
                                    <div className="flex items-center gap-2 group">
                                        <p className="mb-[1px] font-medium text-paragraph leading-5 font-inter border-b border-transparent before:block before:pb-[1px] before:border-purple before:font-medium before:text-purple before:leading-5 before:font-inter before:border-b before:content-['Read_More'] before:absolute before:-bottom-[1px] before:transition-all before:duration-300 before:w-0 hover:before:w-full before:overflow-hidden before:h-[21px] relative">
                                            Read More
                                        </p>
                                        <svg
                                            width="7"
                                            height="11"
                                            viewBox="0 0 7 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                className="transition-all duration-300 delay-300 group-hover:stroke-purple group-hover:delay-0"
                                                d="M1.5 10L5.29289 6.20711C5.62623 5.87377 5.79289 5.70711 5.79289 5.5C5.79289 5.29289 5.62623 5.12623 5.29289 4.79289L1.5 1"
                                                stroke="#6D6D6D"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                        ))}
                        {/* <!-- single blog end --> */}
                        {/* <!-- pagination start --> */}
                        {pagination && (
                            <Pagination
                                pagination={pagination}
                                onPageChange={handlePageChange}
                            />
                        )}
                        {/* <!-- pagination end --> */}
                    </div>
                    {/* <!-- Left Side blogs end  --> */}


                    {/* <!-- Right Side Bar start --> */}
                    <div className="col-span-8 lg:col-span-4">
                        {/* <!-- Category  --> */}
                        <div className="">
                            <h1 className="font-semibold text-22 text-main-black">
                                Category
                            </h1>
                            <ul className="mt-6">
                                {category_list.map((item, index) => (
                                    <li className="my-1.5" key={index}>
                                        <div
                                            onClick={() => handleCategoryClick(item.id)}
                                            className={`flex items-center gap-3 transition-all duration-300 text-paragraph hover:text-purple cursor-pointer ${item.id === selectedCategory ? 'text-purple' : ''}`}
                                        >
                                            <svg
                                                width="6"
                                                height="13"
                                                viewBox="0 0 6 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1 1.92285L4.59655 6.63592L1 11.349"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>

                                            <span>{item.name}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* <!-- Recent blogs  --> */}
                        <div className="mt-[54px]">
                            <h1 className="font-semibold text-22 text-main-black">
                                Recent News
                            </h1>
                            <div className="mt-8">
                                {/* <!-- single --> */}
                                <a
                                    href=""
                                    className="flex gap-2 sm:gap-[25px] items-center group mb-6"
                                >
                                    <div className="w-20 h-[90px] rounded-md overflow-hidden bg-red-100">
                                        <img
                                            src="./assets/images/blogs/recent-1.webp"
                                            alt=""
                                            className="object-cover w-full transition-all duration-300 group-hover:scale-110"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-sm text-paragraph">January 15, 2023</p>
                                        <p className="text-main-black font-semibold mt-1.5 transition-all duration-300 group-hover:underline">
                                            Navigating the Cosmos To Space Exploration Office
                                            Analyzing
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- single --> */}
                                <a
                                    href=""
                                    className="flex gap-2 sm:gap-[25px] items-center group mb-6"
                                >
                                    <div className="w-20 h-[90px] rounded-md overflow-hidden bg-red-100">
                                        <img
                                            src="./assets/images/blogs/recent-1.webp"
                                            alt=""
                                            className="object-cover w-full transition-all duration-300 group-hover:scale-110"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-sm text-paragraph">January 15, 2023</p>
                                        <p className="text-main-black font-semibold mt-1.5 transition-all duration-300 group-hover:underline">
                                            Navigating the Cosmos To Space Exploration Office
                                            Analyzing
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- single --> */}
                                <a
                                    href=""
                                    className="flex gap-2 sm:gap-[25px] items-center group"
                                >
                                    <div className="w-20 h-[90px] rounded-md overflow-hidden bg-red-100">
                                        <img
                                            src="./assets/images/blogs/recent-1.webp"
                                            alt=""
                                            className="object-cover w-full transition-all duration-300 group-hover:scale-110"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-sm text-paragraph">January 15, 2023</p>
                                        <p className="text-main-black font-semibold mt-1.5 transition-all duration-300 group-hover:underline">
                                            Navigating the Cosmos To Space Exploration Office
                                            Analyzing
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        {/* <!-- Tags  --> */}
                        <div className="mt-[54px]">
                            <h1 className="font-semibold text-22 text-main-black">
                                Popular Tags
                            </h1>
                            <div className="mt-8 flex gap-2.5 flex-wrap">
                                {tag_list.map((item, index) => (
                                    <div
                                        className={`group overflow-hidden flex justify-center items-center relative text-sm border border-purple/10 rounded-md py-1.5 before:inline-block before:absolute before:z-0 before:w-full before:h-full before:bg-purple before:scale-x-0 group hover:before:scale-x-100 before:origin-right hover:before:origin-left before:transition-transform before:ease-out before:duration-300 ${item.id === selectedTag ? 'bg-purple text-white' : ''}`}
                                        key={index}
                                        onClick={() => handleTagClick(item.id)}
                                    >
                                        <span className="px-[18px] relative z-20 transition-colors ease-in-out duration-300 group-hover:text-white">
                                            IT Manage
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <!-- banner  --> */}
                        {/* <div className="mt-[30px] flex justify-center items-end relative w-full rounded-2xl overflow-hidden">
                            <img
                                src="./assets/images/service/detail-3.webp"
                                alt=""
                                className="object-cover w-full"
                            />
                            <div className="absolute w-full h-full black_overlay"></div>
                            <div className="w-full max-w-[277px] absolute bottom-[30px]">
                                <h1 className="mb-5 font-semibold text-center text-white text-24">
                                    Modern IT Design Consulting Services
                                </h1>
                                <a href="#">
                                    <div className="inline-flex w-full py-3 home-two-btn-bg group bg-purple border-purple">
                                        <svg
                                            className="relative z-10"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                className="transition-all duration-300 group-hover:fill-purple"
                                                d="M24.1094 2.17188C24.625 2.3125 25 2.73438 25 3.25C25 15.2969 15.25 25 3.25 25C2.6875 25 2.26562 24.6719 2.125 24.1562L1 19.2812C0.90625 18.7656 1.14062 18.2031 1.65625 17.9688L6.90625 15.7188C7.375 15.5312 7.89062 15.6719 8.21875 16.0469L10.5625 18.9062C14.2188 17.1719 17.1719 14.1719 18.8594 10.6094L16 8.26562C15.625 7.9375 15.4844 7.42188 15.6719 6.95312L17.9219 1.70312C18.1562 1.1875 18.7188 0.90625 19.2344 1.04688L24.1094 2.17188Z"
                                                fill="white"
                                            />
                                        </svg>

                                        <span className="relative z-10 text-base text-white transition-all duration-300 group-hover:text-purple font-inter">
                                            +236 (456) 896 22
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </div> */}
                    </div>
                    {/* <!-- Right Side Bar end  --> */}
                </div>
            </section>
        </InnerPageLayout>
    );
}
